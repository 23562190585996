













import { Watch, Prop, Component, Vue } from 'vue-property-decorator';
import MESSAGE_LEVEL from '../Constants/MessageLevel';

@Component({
    inheritAttrs: false,
})
export default class EmailFormInput extends Vue {

    @Prop()
    public value!: string;

    @Prop()
    public level!: MESSAGE_LEVEL;

    @Prop()
    public message?: string;

    public display: string;

    constructor() {
        super();

        this.display = this.value;
    }

    public mounted() {
        (this.$refs.input as HTMLInputElement).focus();
    }

    get classesParent() {
        return {
            'form-group': true,
            'm-form__group': true,
            'has-info': (this.level === MESSAGE_LEVEL.INFO),
            'has-warning': (this.level === MESSAGE_LEVEL.WARNING),
            'has-danger': (this.level === MESSAGE_LEVEL.ERROR || (this.display && !this.isValid)),
            'has-success': (this.level === MESSAGE_LEVEL.SUCCESS || (this.display && this.isValid)),
        };
    }

    get isValid() {
        return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(this.display);
    }

    @Watch('display')
    public onDisplayChange() {
        this.$emit('input', this.isValid ? (this.$refs.input as HTMLInputElement).value : null);
    }
}
