import VueRouter from 'vue-router';
import routes from '@/Domains/index';

const router = new VueRouter({
    base: '/auth/',
    mode: 'history',
    ...routes,
});

export default router;
