














































import {Component, Vue} from 'vue-property-decorator';
import SideContent from '@/Domains/Auth/Components/SideContent.vue';
import axios from '@/Support/Http/Axios';
import {Action, Getter} from 'vuex-class';

import * as _ from 'lodash';

@Component({
    components: {
        SideContent,
    },
})
export default class UserMailUnchecked extends Vue {

    public content: string;
    public btnResendLoading: boolean;
    public btnProceedLoading: boolean;

    @Getter('getCompany', { namespace: 'auth' })
    public getCompany: any;

    @Action('createSession', {namespace: 'auth'})
    public createSession: any;

    constructor() {
        super();

        this.content = '';
        this.btnResendLoading = false;
        this.btnProceedLoading = false;
    }

    public mounted() {

        /**
         * Verifica se já foi enviado email de confirmação para o usuário
         */
        if (!this.company.email_enviado) {
            this.sendMail();
        }

    }

    get company(): any {

        const { userId, companyId } = this.$route.params;

        const selectedCompany = this.getCompany({userId, companyId});

        if (_.isEmpty(selectedCompany)) {
            return this.$router.replace('/login');
        }

        return selectedCompany;
    }

    public async resend() {
        this.btnResendLoading = true;
        try {

            const response = await this.sendMail();

            if (!response.success) {
                return this.$router.replace('/login');
            }

            this.content = `Um novo email de confirmação foi enviado. Por favor verifique em sua caixa de entrada, ou então na caixa de spam(Lixo Eletrônico)`;
        } catch (e) {
            window.console.warn(e);
        }
    }

    public async sendMail() {
        try {

            const { id_empresa, id_pessoa, email } = this.company;

            const {data} = await axios.post('/login/reenviaEmailConfirmacao', {
                id_empresa,
                id_pessoa,
                email,
            });

            return data;
        } catch (e) {
            window.console.warn(e);
        }
    }

    public proceed() {
        this.btnProceedLoading = true;
        return this.createSession(this.company);
    }
}
