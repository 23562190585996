







































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {Company} from '../Types';

import * as _ from 'lodash';

@Component({})
export default class UserCompanies extends Vue {

    public currentPage: number;
    public pageSize: number;
    public companies: Company[];
    public loading: boolean;
    public fullPage: boolean;

    public search: string;

    @Getter('getCompanies', {namespace: 'auth'})
    public getCompanies: any;

    @Action('validateUser', {namespace: 'auth'})
    public validateUser: any;

    constructor() {
        super();

        this.pageSize = 16;
        this.currentPage = 1;
        this.search = '';
        this.companies = [];
        this.loading = false;
        this.fullPage = false;
    }

    public mounted() {

        /**
         * Inicializa a lista de empresas com todos os registros da store
         */
        this.companies = this.getCompanies('');

        if (_.isEmpty(this.companies)) {
            return this.$router.replace('/login');
        }

        document.title = 'SIMPLLIS - EMPRESAS';
    }

    get totalPages() {
        return Math.ceil(this.companies.length / this.pageSize);
    }

    get paginatedCompanies() {
        const start = (this.currentPage - 1) * this.pageSize;

        return this.companies.slice(start, start + this.pageSize);
    }

    get paginationRange() {
        const start = this.currentPage - 3;
        const end = this.currentPage + 4;

        return _.range(start < 1 ? 1 : start, end > this.totalPages ? this.totalPages + 1 : end);
    }

    @Watch('search')
    public onSearch() {
        return _.debounce(() => {
            this.companies = this.getCompanies(this.search);
        }, 200)();
    }

    public previousPage() {
        if (this.currentPage > 1) {
            --this.currentPage;
        }
    }

    public nextPage() {
        if (this.currentPage < this.totalPages) {
            ++this.currentPage;
        }
    }

    public firstPage() {
        this.currentPage = 1;
    }

    public lastPage() {
        this.currentPage = this.totalPages;
    }

    public setCurrentPage(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
        }
    }

    public selectCompany(company: Company) {

        this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: '#36a3f7',
        });

        return this.validateUser(company);
    }

    public onCancel() {
        window.console.log('User cancelled the loader.');
    }

}
