

























































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import * as _ from 'lodash';
import { VueRecaptcha } from 'vue-recaptcha';

import EmailFormInput from '../Components/EmailFormInput.vue';
import PasswordFormInput from '../Components/PasswordFormInput.vue';
import MESSAGE_LEVEL from '@/Domains/Auth/Constants/MessageLevel';
import SideContent from '@/Domains/Auth/Components/SideContent.vue';

@Component({
    components: {
        EmailFormInput,
        PasswordFormInput,
        SideContent,
        VueRecaptcha,
    },
})
export default class LoginForm extends Vue {
    $refs!: {
        recaptcha: HTMLFormElement
    }

    public email: string;
    public password: string;
    public recaptchaSiteKey: string;
    public recaptcha: string;
    public remember: boolean;
    public loading: boolean;

    @State('auth')
    public auth: any;

    @Action('resetProcessing', { namespace: 'auth' })
    public resetProcessing: any;

    @Action('authenticate', {namespace: 'auth'})
    public authenticate: any;

    @Action('createSession', {namespace: 'auth'})
    public createSession: any;

    @Action('validateUser', {namespace: 'auth'})
    public validateUser: any;

    constructor() {
        super();

        this.recaptchaSiteKey = window.__RECAPTCHA_SITE_KEY;

        this.email = '';
        this.password = '';
        this.recaptcha = '';
        this.remember = false;
        this.loading = false;
    }

    public mounted() {
        this.resetProcessing();
        this.auth.error = 0;
        this.auth.errors = [];
        document.title = 'SIMPLLIS - LOGIN';
    }

    public onCaptchaVerified(recaptchaToken: string) {
        this.recaptcha = recaptchaToken
    }

    public onCaptchaExpired() {
        // this.$refs.recaptcha;
        this.recaptcha = '';
    }

    public async signIn() {
        this.loading = true;

        return this.authenticate({email: this.email, password: this.password, _tk: this.recaptcha });
    }

    public recoveryPassword() {

        const email = this.email;

        if (_.isEmpty(email)) {
            return this.$router.push({path: '/usuario/recuperar-senha'});
        }

        return this.$router.push({path: '/usuario/recuperar-senha', query: {email}});
    }

    @Watch('auth.companies')
    public onCompaniesLoaded() {
        /**
         * Caso seja retornado apenas um resultado, verificar os termos de uso do sistema
         * Após a conclusão o usuário será redirecionado ao sistema
         */
        if (this.auth.companies.length === 1) {
            return this.validateUser(this.auth.companies[0]);
        }

        return this.$router.push({path: '/usuario/empresas'});
    }

    @Watch('auth.error')
    public onAuthenticationError() {
        if (this.auth.level === MESSAGE_LEVEL.NONE) {
            return;
        }
        if (this.auth.error.level) {
            this.loading = false;
            this.$refs.recaptcha.reset();
        }
    }

}
