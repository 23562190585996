






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import axios from 'axios';
import * as _ from 'lodash';

@Component
export default class UserAgreementText extends Vue {

    @Prop()
    public term!: any;

    public text: string;

    constructor() {
        super();

        this.text = '';
    }

    public handleScroll(event: any) {
        if (((event.target.scrollHeight - event.target.offsetHeight) - event.target.scrollTop) <= 2) {
            this.$emit('ready', true);
        }
    }

    @Watch('term')
    public async handleTermChange() {
        let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false,
            onCancel: () => {},
            color: '#36a3f7',
        });

        try {

            if (_.isEmpty(this.term)) {
                return;
            }

            const { data } = await axios.get(`${window.__JURIDICAL_API}/${this.term.type.toLowerCase()}`,
                {
                    headers: {
                        Authorization: `Bearer ${window.__JURIDICAL_API_KEY}`,
                    },
                },
            );

            this.text = data.texto;
            (this.$refs.agreement as HTMLDivElement).scroll(0, 0);
        } catch (e) {
            window.console.warn(e);
            this.$emit('error', e);
        } finally {
            loader.hide();
        }
    }
}
