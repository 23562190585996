import {Module} from 'vuex';

import {RootState} from '@/Support/Store/Types/RootState';
import {AuthState} from '@/Domains/Auth/Types';

import {mutations} from '@/Domains/Auth/Store/AuthMutations';
import {actions} from '@/Domains/Auth/Store/AuthActions';
import {getters} from '@/Domains/Auth/Store/AuthGetters';
import MESSAGE_LEVEL from '@/Domains/Auth/Constants/MessageLevel';

export const state: AuthState = {
    isProcessing: false,
    companies: [],
    location: {},
    error: {
        level: MESSAGE_LEVEL.NONE,
        message: '',
    },
    errors: [],
};

const namespaced: boolean = true;

export const auth: Module<AuthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
