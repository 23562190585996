






import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import * as _ from 'lodash';

@Component
export default class Index extends Vue {

    @Mutation('setLocation', { namespace: 'auth' })
    public setLocation: any;

    public created() {
        if (process.env.NODE_ENV === 'development') {
            this.$router.push({path: '/login'});
        }
    }

    public async mounted() {
        await this.getLocation();
    }

    public async getLocation() {
        try {
            const { coords }: any = await this.getCurrentPosition();

            if (!coords) {
                return;
            }

            const { latitude, longitude } = coords;

            this.setLocation({
                latitude: _.ceil(latitude, 10),
                longitude: _.ceil(longitude, 10),
            });
        } catch (e) {
            window.console.warn(e);
        }
    }

    public getCurrentPosition() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true });
            }
        });
    }
}
