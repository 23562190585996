
































import {Component, Vue} from 'vue-property-decorator';
import SideContent from '@/Domains/Auth/Components/SideContent.vue';
import axios from '@/Support/Http/Axios';

@Component({
    components: {
        SideContent,
    },
})
export default class UserMailCheck extends Vue {

    public success: boolean;
    public message: string;
    public content: string;
    private token: string;

    constructor() {
        super();

        this.message = '';
        this.content = '';
        this.success = false;
        this.token = '';
    }

    public mounted() {
        const {token} = this.$route.params;

        if (!token) {
            return this.$router.push('/login');
        }

        return this.handleEmailCheck(token);
    }

    public async handleEmailCheck(token: string) {
        try {

            const {data} = await axios.post('/login/validaEmail', {token});

            if (!data.success) {
                return this.setErrorStatus();
            }

            this.success = true;
            this.message = 'Email confirmado!';

            if (!data.token) {
                this.content = 'Redirecionando para o login...';
                return setTimeout(() => this.$router.push({path: `/login`}), 5000);
            }

            this.content = 'Para prosseguir com o acesso, vamos criar uma nova senha...';
            this.token = data.token;
        } catch (e) {
            return this.setErrorStatus();
        }
    }

    public setErrorStatus() {
        this.success = false;
        this.message = 'Erro ao confirmar email!';
        this.content = 'Por favor, tente novamente!';
    }

    public createPassword() {
        this.$router.push({path: '/usuario/altera-senha', query: {_tk: this.token}});
    }
}
