
















import { Watch, Prop, Component, Vue } from 'vue-property-decorator';
import MESSAGE_LEVEL from '../Constants/MessageLevel';

@Component({
    inheritAttrs: false,
})
export default class PasswordFormInput extends Vue {

    @Prop()
    public value!: string;

    @Prop()
    public level!: MESSAGE_LEVEL;

    @Prop()
    public message?: string;

    public display: string;

    private showPass: boolean;

    constructor() {
        super();

        this.display = this.value;
        this.showPass = false;
    }

    get classesParent() {
        return {
            'form-group': true,
            'm-form__group': true,
            'has-info': this.level === MESSAGE_LEVEL.INFO,
            'has-warning': this.level === MESSAGE_LEVEL.WARNING,
            'has-danger': this.level === MESSAGE_LEVEL.ERROR,
            'has-success': this.level === MESSAGE_LEVEL.SUCCESS,
        };
    }

    public toggleShowHidePass() {
        this.showPass = !this.showPass;
    }

    @Watch('display')
    public onDisplayChange() {
        this.$emit('input', (this.$refs.input as HTMLInputElement).value);
    }
}
