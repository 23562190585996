import { MutationTree } from 'vuex';
import { AuthState, Company } from '../Types';
import AuthenticationError from '@/Domains/Auth/Types/AuthenticationError';

export const mutations: MutationTree<AuthState> = {

    processingStateChange(state, payload: boolean) {
        state.isProcessing = payload;
    },

    companiesLoaded(state, payload: Company[]) {
        state.companies = payload;
    },

    authenticationError(state, error: AuthenticationError) {
        state.error = error;
    },

    acceptAgreement(state: any, { term, company }) {
        state.companies = state.companies.map((comp: Company) => {

            if (comp.id_empresa === company.id_empresa) {

                comp.termos[term.type] = 1;

                return comp;
            }

            return company;
        });
    },

    setLocation(state: any, location) {
        state.location = location;
    },
};
