import Index from '@/Domains/Auth/Views/Index.vue';
import Login from '@/Domains/Auth/Views/Login.vue';
import UserAgreements from '@/Domains/Auth/Views/UserAgreements.vue';
import UserCompanies from '@/Domains/Auth/Views/UserCompanies.vue';
import UserPasswordRecovery from '@/Domains/Auth/Views/UserPasswordRecovery.vue';
import UserMailCheck from '@/Domains/Auth/Views/UserMailCheck.vue';
import UserCreatePassword from '@/Domains/Auth/Views/UserCreatePassword.vue';
import UserMailUnchecked from '@/Domains/Auth/Views/UserMailUnchecked.vue';
import UserMailInvalid from '@/Domains/Auth/Views/UserMailInvalid.vue';

export default [
    {
        path: '/',
        component: Index,
        children: [
            {
                path: 'login',
                component: Login,
            },
            {
                path: 'usuario/termos/:companyId/:userId',
                component: UserAgreements,
            },
            {
                path: 'usuario/empresas',
                component: UserCompanies,
            },
            {
                path: 'usuario/valida-email/:token',
                component: UserMailCheck,
            },
            {
                path: 'usuario/confirmacao-pendente/:companyId/:userId',
                component: UserMailUnchecked,
            },
            {
                path: 'usuario/email-invalido/:companyId/:userId',
                component: UserMailInvalid,
            },
            {
                path: 'usuario/recuperar-senha',
                component: UserPasswordRecovery,
            },
            {
                path: 'usuario/altera-senha',
                component: UserCreatePassword,
            },
        ],
    },
];
