import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import VuexPersistence from 'vuex-persist';

import { RootState} from '@/Support/Store/Types/RootState';
import { auth } from '@/Domains/Auth/Store/AuthStore';


Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
    storage: window.localStorage,
});

const store: StoreOptions<RootState> = {
    state: {
    },
    modules: {
        auth,
    },
    plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
