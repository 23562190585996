


















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import WithRoute from '@/Domains/Auth/Types/WithRoute';

import * as _ from 'lodash';
import UserAgreementText from '@/Domains/Auth/Components/UserAgreementText.vue';

const uuid = require('uuid/v4');
@Component({
    components: {
        UserAgreementText,
    },
})
export default class UserAgreements extends Vue implements WithRoute {

    public currentStep: number;
    public lastStep?: number;
    public isReady: boolean;
    public location: object | null;
    public hash: string;
    public tabs: any;

    @Action('acceptAgreement', { namespace: 'auth' })
    public acceptAgreement: any;

    @Action('createSession', { namespace: 'auth' })
    public createSession: any;

    @Getter('getCompany', { namespace: 'auth' })
    public getCompany: any;

    constructor() {
        super();

        this.currentStep = 0;
        this.lastStep = undefined;
        this.isReady = false;
        this.location = null;
        this.hash = uuid();
        this.tabs = [];
    }

    public async mounted() {
        /**
         * Load route params
         */
        const {userId, companyId} = this.$route.params;

        if (_.isEmpty(companyId) || _.isEmpty(userId)) {
            return this.$router.replace('/login');
        }

        /**
         * Atualiza a localização do usuário
         */
        await this.loadTerms();

        this.currentStep = this.firstStep;
    }

    get company(): any {

        const { userId, companyId } = this.$route.params;

        const selectedCompany = this.getCompany({userId, companyId});

        if (_.isEmpty(selectedCompany)) {
            return this.$router.replace('/login');
        }

        return selectedCompany;
    }

    get name() {

        const name = _.get(this.company, 'nome_pessoa');

        return _.split(name, ' ', 1).toString();
    }

    get activeTerm() {
        const term = this.tabs[this.currentStep];

        if (!term) {
            return  {};
        }

        return  term;
    }

    get firstStep(): number {
        return _.findIndex(this.tabs, (item: any)  => item.enabled);
    }

    get hasNext() {
        const nextTerm = this.tabs[this.currentStep + 1];

        if (!nextTerm) {
            return false;
        }

        if (!nextTerm.enabled) {
            return false;
        }

        return true;
    }

    public async loadTerms() {

        const { termos }  = this.company;

        this.tabs = await Promise.all(_.map(termos, async (value, key) => {
            switch (key) {
                case 'EULA':
                    return {
                        type: 'EULA',
                        title: 'Termos de Uso',
                        icon: 'fa-file-alt',
                        enabled: value === 0,
                        accepted_at: '',
                        auth: '',
                        location: '',
                    };
                case 'PRIVACY':
                    return {
                        type: 'PRIVACY',
                        title: 'Política de Privacidade',
                        icon: 'fa-user-secret',
                        enabled: value === 0,
                        accepted_at: '',
                        auth: '',
                        location: '',
                    };
                case 'COOKIE':
                    return {
                        type: 'COOKIE',
                        title: 'Política de Cookies',
                        icon: 'fa-cookie-bite',
                        enabled: value === 0,
                        accepted_at: '',
                        auth: '',
                        location: '',
                    };
            }
        }));
    }

    public async confirm() {
        let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false,
            onCancel: () => {
            },
            color: '#36a3f7',
        });

        try {
            /**
             * Preeche os dados necessários para o aceite
             */
            const term = this.tabs[this.currentStep];
            term.auth = this.hash;
            term.accepted_at = new Date().toISOString();
            term.location = this.location;

            this.acceptAgreement({
                term,
                company: this.company,
            });

            if (!this.hasNext) {
                loader.hide();
                return this.createSession(this.company);
            }

            ++this.currentStep;
            this.isReady = false;
        } catch (e) {
            window.console.warn(e);
        } finally {
            loader.hide();
        }
    }

    public cancel() {
        if (this.currentStep > 1) {
            --this.currentStep;
            this.isReady = false;
        }
    }

    @Watch('currentStep')
    public functionHandlePage() {
        /**
         * Sempre que mudar de tela, regenerar o código de autenticação
         */
        this.hash = uuid();
    }

    /**
     * Adiciona handle de erros ao tentar carregar os termos de uso.
     */
    public handleTextLoadError() {
        return this.createSession(this.company);
    }
}
