import Vue from 'vue';
import VueRouter from 'vue-router';
import VeeValidate, { Validator } from 'vee-validate';
import router from '@/Support/Http/Router';
import App from './App.vue';
import store from '@/Support/Store/Vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const zh = require('vee-validate/dist/locale/pt_BR');

Vue.config.productionTip = true;

Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.use(Loading);

Validator.localize('pt_BR', zh);

if (process.env.NODE_ENV === 'development') {
    window.__JURIDICAL_API = '';
    window.__JURIDICAL_API_KEY = '';
    window.__RECAPTCHA_SITE_KEY = '6Lf_UcgiAAAAANJ_F7ajXNWrdGf21GqRuf49jQE7';
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
