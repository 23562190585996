












import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class SideContent extends Vue {

    public show: boolean;

    constructor() {
        super();

        this.show = false;
    }

    get style() {
        return {
            display: this.show ? 'flex' : 'none', // Trigger CSS animation.
        };
    }

}
