



























































import {Component, Vue} from 'vue-property-decorator';
import SideContent from '@/Domains/Auth/Components/SideContent.vue';

import {ValidationObserver, ValidationProvider} from 'vee-validate';

import axios from '@/Support/Http/Axios';
import * as _ from 'lodash';

@Component({
    components: {
        SideContent,
        ValidationObserver,
        ValidationProvider,
    },
})
export default class UserPasswordRecovery extends Vue {

    public email: any;
    public content: string;
    public error: string;
    public processing: boolean;

    constructor() {
        super();

        this.email = '';
        this.content = '';
        this.error = '';
        this.processing = false;
    }

    public mounted() {
        const {email} = this.$route.query;

        this.email = email;

        document.title = 'SIMPLLIS - RECUPERA SENHA';
    }

    public async onSubmit() {
        try {
            this.processing = true;

            const {data} = await axios.post('/login/redefinirSenha', {
                email: this.email,
            });

            this.processing = false;

            if (_.isEmpty(data) || !data.success) {
                this.error = data.error;
                return;
            }

            this.content = 'Enviamos para você um email com as informações necessárias para redefinir sua senha!';
            return setTimeout(() => this.$router.push({path: '/login'}), 10000);

        } catch (e) {
            this.processing = false;
            this.error = 'Erro ao solicitar recuperação de senha, tente novamente!';
        }
    }
}
