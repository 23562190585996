import { GetterTree } from 'vuex';
import { AuthState } from '../Types';
import { RootState } from '@/Support/Store/Types/RootState';

import * as _ from 'lodash';

interface GetCompany {
    userId: string;
    companyId: string;
}

export const getters: GetterTree<AuthState, RootState> = {

    getCompanies(state) {
        return (query: string) => {

            const companies = state.companies || [];

            if (_.isEmpty(query)) {
                return companies;
            }

            return companies.filter((company) => {
                return [company.id_empresa, company.nome_empresa, company.cpf_cnpj].join(' ').toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
        };
    },

    getCompany(state) {
        return ({ userId, companyId }: GetCompany) => {

            let companies = state.companies || [];

            companies = companies.filter((company) => {
                return company.id_empresa === parseInt(companyId, 10) && company.id_pessoa === parseInt(userId, 10);
            });

            return _.first(companies);
        };
    },
};
