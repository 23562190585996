






















































import {Watch, Component, Vue} from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import axios from '@/Support/Http/Axios';
import * as _ from 'lodash';

const PasswordMeter = require('vue-password-strength-meter');

import SideContent from '@/Domains/Auth/Components/SideContent.vue';

@Component({
    components: {
        SideContent,
        PasswordMeter,
        ValidationObserver,
        ValidationProvider,
    },
})
export default class UserCreatePassword extends Vue {

    public password: string;
    public confirm: string;
    public token: any;
    public error: string;
    public score: number;
    public securityError: string;

    constructor() {
        super();

        this.password = '';
        this.confirm = '';
        this.token = '';
        this.error = '';
        this.score = 0;
        this.securityError = ``;
    }

    public mounted() {

        const {_tk} = this.$route.query;

        if (!_tk) {
            return this.$router.push('/login');
        }

        this.token = _tk;
    }

    public async onSubmit() {
        try {

            if (this.score < 2) {
                this.securityError = `
                    Senha muito fraca!
                    <br />
                    <br />
                    Uma senha forte é composta por <b>letras maiúsculas</b>, <b>minúsculas</b> e <b>números</b>, de forma que não constituam uma palavra comum. <br />
                    Use <b>sinais de pontuação</b> ou <b>caracteres especiais</b> na senha, por exemplo, ponto final, ponto e virgula, etc.
                `;

                return;
            }

            const {data} = await axios.post('/login/alteraSenha', {
                _tk: this.token,
                password: this.password,
            });

            if (_.isEmpty(data) || !data.success) {
                this.error = 'Erro ao atualizar senha, por favor tente novamente!';
                return;
            }

            return this.$router.push({path: '/login'});
        } catch (e) {
            this.error = 'Erro ao atualizar senha, por favor tente novamente!';
        }
    }

    public scoreHanddler(score: number) {
        this.score = score;
    }

    @Watch('password')
    public onPasswordChange() {
        this.securityError = ``;
    }

    @Watch('confirm')
    public onPasswordConfirmationChange() {
        this.securityError = ``;
    }
}
